.l-footer {

    &__top {
        row-gap: var(--grid-gutter-lg);
        padding-bottom: var(--base-margin);

        @include screen('md') {
            padding-bottom: calc( var(--base-margin) * 2 );
        }
    }

    &__newsletter {
        .l-form {
            max-width: 60rem;
        }
    }
    &__bottom {

	    display: flex;
	    flex-direction: column-reverse;
	    align-items: center;
	    padding-top: var(--base-margin);
	    font-size: $fs-base;

	    @include screen('sm') {
		    flex-direction: row;
		    justify-content: space-between;
	    }

	    .l-footer__copyright {
		    padding-top: calc(var(--base-margin) * 2);

		    @include screen('sm') {
			    padding-top: 0;
		    }
	    }
    }
}
