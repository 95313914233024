.l-container {
    width: $grid-width;
    max-width: $grid-max-width;
    margin: 0 auto;
    padding: var(--base-margin);
}

.l-grid {
    display: grid;
    grid-template-columns: repeat($grid-columns, 1fr);
    grid-gap: var(--grid-gutter);
    gap: var(--grid-gutter);

    @include create-selectors;

    @each $breakpoint, $width in $breakpoints {
        @include screen(#{$breakpoint}) {
            @include create-selectors($breakpoint);
        }
    }
}
