:root {
	--base-margin: #{$margin};
	--base-font-size: 10px;
	--grid-gutter: #{$gutter};
    --grid-gutter-lg: #{$gutter*2};

    --color-forest: #{$color-forest};
    --color-rust: #{$color-rust};
    --color-herbs: #{$color-herbs};
    --color-snow: #{$color-snow};
    --color-white: #{$color-white};

	@include screen('sm') {
		--base-margin: calc(#{$margin} * 2);
		--grid-gutter: calc(#{$gutter} * 2);
	}
}

::selection {
	color: $color-snow;
	background: $color-herbs;
}

* {
	position: relative;
}

html {
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-moz-osx-font-smoothing: antialiased;
	font-size: var(--base-font-size);
    scroll-behavior: smooth;
    scroll-padding-top: calc( var(--header-height) * 2/4);

    @include screen('md') {
        scroll-padding-top: calc( var(--header-height) * 3/4);
    }
}

body {
    font-size: $fs-base;
	font-family: $ff-maison;
	color: $color-forest;
	background: $color-snow;

	&.-preload {
		* {
			transition: none !important;
		}
	}
}

