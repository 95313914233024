/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin screen($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
	// Prints a media query based on the value
	@media #{inspect(map-get($breakpoints, $breakpoint))} {
	  @content;
	}
  }

  // If the key doesn't exist in the map
  @else {
	@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		+ "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin fit-to-parent($position: absolute){
  position: $position;
  top:0;
  right:0;
  bottom:0;
  left:0;
}

/**
 * Responsive typography
 * @author Mike Riethmuller http://codepen.io/MadeByMike/pen/YPJJYv
 * @param  {integer} $min-width: 0            The breakpoint minimum to activate the responsive typography
 * @param  {integer} $max-width: 2560         The breakpoint maximum to activate th responsive typography
 * @param  {integer} $min-font:  12           The minimum font-size for the element
 * @param  {integer} $max-font:  16           The maximum font-size for the element
 * @return {void}
 */
@mixin responsive-type(
    $min-font: 12,
    $max-font: 16,
    $min-width: 480,
    $max-width: 1440) {

    // Set min size
    font-size: $min-font * 1px;

    // Adjust size between `$min-width` et `$max-width`
    @media (min-width: #{$min_width}px) and (max-width: #{$max_width}px){
        font-size: calc( #{$min_font}px + (#{$max_font} - #{$min_font}) * ( (100vw - #{$min_width}px) / ( #{$max_width} - #{$min_width}) ));
    }

    // Set max size
    @media (min-width: #{$max_width}px){
        font-size: #{$max_font}px;
    }
}



@mixin create-selectors($breakpoint: null) {
    $infix: if($breakpoint == null, '', '\\@#{$breakpoint}');

    @for $i from 1 through $grid-columns {
        .col-#{$i}#{$infix} {
            grid-column-end: span $i;
        }
        .col-offset-#{$i}#{$infix} {
            grid-column-start: $i + 1;
        }
        .row-#{$i}#{$infix} {
            grid-row-end: span $i;
        }
        .row-offset-#{$i}#{$infix} {
            grid-row-start: $i + 1;
        }
    }
}

@mixin create-utilities-selectors($breakpoint: null) {
    $infix: if($breakpoint == null, '', '\\@#{$breakpoint}');

    // Display
    .u-dp-block#{$infix}         { display: block !important; }
    .u-dp-none#{$infix}          { display: none !important; }
    .u-dp-table#{$infix}         { display: table !important; }
    .u-dp-table-cell#{$infix}    { display: table-cell !important; }
    .u-dp-inline-block#{$infix}  { display: inline-block !important; }
    .u-dp-inline#{$infix}        { display: inline !important; }
    .u-dp-inherit#{$infix}       { display: inherit !important; }
    .u-dp-flex#{$infix}          { display: flex !important; }
    .u-dp-inline-flex#{$infix}   { display: inline-flex !important; }
    .u-dp-grid#{$infix}          { display: grid !important; }

    // Typography alignment
    .u-ta-center#{$infix}    { text-align: center !important; }
    .u-ta-left#{$infix}      { text-align: left !important; }
    .u-ta-right#{$infix}     { text-align: right !important; }
}
