.c-menu {

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;

    width: 100vw;
    color: var(--color-snow);
    background: var(--color-herbs);

    @include screen('md') {
        width: 75vw;
        max-width: 100rem;
    }

    &__inner {
        height: 100%;
        overflow-y: auto;
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;

        padding: var(--base-margin);
        padding-top: calc( var(--header-height) + var(--base-margin) * 2 );

        @include screen('md') {
            padding-top: calc( var(--header-height) + var(--base-margin) );
        }
    }

    &__item {
        margin-bottom: var(--base-margin);
        padding-bottom: .325em;
        overflow: hidden;
    }

    &__link {

        .o-icon {
            --svg-width: .65em;
            margin-left: .5em;
            vertical-align: middle;
        }
    }


    pointer-events: none;
    transform: translateX(100%);
    opacity: 0;
    will-change: transform;
    transition: transform .25s ease-in-out, opacity 0s linear .25s;

    [open] & {
        pointer-events: auto;
        transform: translateX(0);
        opacity: 1;
        transition: transform .6s ease-in-out;
    }
}
