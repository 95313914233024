.s-secrets {
    padding-bottom: 0;

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--grid-gutter-lg) 0;
        padding-bottom: calc( var(--grid-gutter-lg) * 2 );

        @include screen('md') {
            padding: var(--grid-gutter-lg) calc( var(--grid-gutter-lg) * 2 );
        }
        @include screen('lg') {
            padding: var(--grid-gutter-lg) calc( var(--grid-gutter-lg) * 4 );
        }
        @include screen('xl') {
            padding: var(--grid-gutter-lg) 0;
        }

        .t-content {
            --content-gap: 2rem;

            @include screen('md') {
                --content-gap: 4rem;
            }
        }
    }

    &__slider {
        padding-top: 100%;
        order: -1;

        @include screen('md') {
            order: unset;
        }
        .c-slider {
            left: calc( var(--base-margin) * -1 );
            right: calc( var(--base-margin) * -1 );

            @include screen('md') {
                left: 0;
            }
        }
    }
}
