.c-slider {
    @include fit-to-parent();
    overflow: hidden;

	.flickity-viewport, .flickity-slider {
		height: 100%;
	}

	&:focus, &:active {
		outline: none;
	}

	&__item {
		@extend %absolute;
		overflow: hidden;

		picture {

			position: absolute;
			top: 0;
			left: calc( var(--base-margin) * -2);
			right: calc( var(--base-margin) * -2);
			bottom: 0;
			transform: translateX(5%);
			transition: transform 1s ease-out;

			content-visibility: auto;

			img {
				@extend %absolute;
				object-fit: cover;
				object-position: center center;
			}
		}

		&.is-selected {
			picture {
				transform: translateX(0%);
			}
		}
	}

	.flickity-page-dots {
		position: absolute;
		left: 50%;
		bottom: var(--base-margin);
		list-style: none;
		transform: translateX(-50%);
		text-align: center;

		.dot {
			display: inline-block;
			width: 1.2rem;
			height: 1.2rem;
			margin: 1rem;
			background: $color-snow;
			border-radius: .6rem;
			cursor: pointer;
			overflow: hidden;

			&:after {
                @include fit-to-parent();
				content: '';
				background: $color-herbs;
				border-radius: .6rem;

				transform: translateY(1rem) scale(1.1);
				opacity: 0;
				transition: transform .6s $in-out-quad, opacity .4s $in-out-quad;
			}

			&.is-selected {
				width: 2.5rem;

				&:after {
					transform: translateY(0) scale(1.1);
					opacity: 1;
				}
			}

			// Animations
			transform: translateY(0) rotate(0deg);
			transform-origin: top left;
			opacity: 1;

			@for $i from 1 through 5 {
				&:nth-child(#{$i}) {
					$delay: #{2.4 + $i * 0.25}s;
					transition: transform .9s $in-out-quad $delay, opacity .7s $in-out-quad $delay, width .25s $in-out-quad 0s;
				}
			}
		}
	}
}

.-preload {

	.flickity-page-dots .dot {
		transform: translateY(.5rem) rotate(5deg);
		opacity: 0;
	}
}
