.c-video {
	pointer-events: none;

	&__wrapper {
		@include fit-to-parent();
		z-index: -1;
		overflow: hidden;

		iframe {
			width: 100vw;
			height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
			min-height: 100vh;
			min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

}
