.s-auto-layout {
    background: var(--color-white);

    padding: var(--grid-gutter) 0;

    @include screen('md') {
        padding-bottom: 0
    }

    &__slider {

        .c-auto-slider {
            --auto-slider-offset: var(--base-margin);
            margin-left: calc( var(--base-margin) * -1 );
            width: calc(100% + var(--base-margin) * 2 );

            @include screen('md') {
                --auto-slider-offset: 0;
                margin-left: 0;
                width: 100%;
            }
        }
    }

    &__content {
        --content-x: 0;
        --content-y: var(--grid-gutter-lg);
        padding: var(--content-y) var(--content-x);



        @include screen('md') {
            --content-x: calc( var(--grid-gutter-lg) * 2 );
            --content-y: var(--content-x);
        }
        @include screen('lg') {
            --content-x: calc( var(--grid-gutter-lg) * 4 );
            --content-y: calc( var(--grid-gutter-lg) * 4 );
        }
        @include screen('xl') {
            --content-x: 0;
        }

        .-sticky {
            position: sticky;
            top: calc( var(--header-height, 14rem) );
        }

        .t-content {
            --content-gap: 2rem;
            --content-actions-extra-gap: 2rem;

            @include screen('md') {
                --content-gap: 3rem;
                --content-actions-extra-gap: 1rem;
            }
        }
    }

    &.-refuges {

        .s-auto-layout__slider {
            .-slide {

                @include screen('md') {
                    &:nth-child(2n) {
                        padding-left: 6rem;
                        padding-right: 6rem;
                    }
                }
            }
        }
    }

    &.-campings {
        margin-top: 1px;
        @include screen('md') {
            margin-top: 0;
            padding-bottom: var(--base-margin);
        }

        .s-auto-layout__slider {
            order: -1;

            @include screen('md') {
                order: unset;
            }

            .-slide {

                @include screen('md') {
                    &:nth-child(2n-1) {
                        padding-left: 6rem;
                        padding-right: 6rem;
                    }
                }
            }
        }
    }
}
