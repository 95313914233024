.c-auto-slider {
    --auto-slider-gutter: var(--base-margin);
    --auto-slider-slide-width: 80%;
    --auto-slider-offset: 0;
    display: block;
    width: 100%;

    &__inner {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: var(--auto-slider-slide-width);
        grid-gap: var(--auto-slider-gutter);
        gap: var(--auto-slider-gutter);
        align-items: center;

        width: 100%;

        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        scroll-padding-left: var(--auto-slider-offset); // Snap with an offset
        -webkit-overflow-scrolling: touch;

        @include screen('md') {
            --auto-slider-gutter: calc( var(--base-margin) * 2 );
            grid-auto-columns: initial;
            grid-auto-flow: row;
        }

        &:before,
        &:after {
            display: block;
            content: '';
            width: var(--auto-slider-offset);

            @include screen('md') {
                content: none;
            }
        }

        // Scrollbar
        scrollbar-color: var(--color-brown) var(--color-beige);
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
            width: 0.4rem;
            height: 0.4rem;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--color-herbs);
          border-radius: 0.4rem;
          border: 0;
        }

        &::-webkit-scrollbar-track {
          background: var(--color-snow);
          border-radius: 0.4rem;
        }

        .no-js & {
          -ms-overflow-style: auto;
          scrollbar-width: auto;
        }

        .no-js &::-webkit-scrollbar {
          display: initial;
        }

        > * {
            scroll-snap-align: center;

            &:first-of-type {
                scroll-snap-align: start;
            }
            &:last-of-type {
                scroll-snap-align: center;
            }
        }
    }
}
