.o-menu-trigger {
    display: grid;
    grid-auto-flow: column;
    gap: 2.4rem;

    &__label {
        min-width: 6ch;
    }

    &__icon {

        width: 1.8rem;
        height: 1px;
        background: currentColor;

        transform: scaleX(0.6);

        transition: transform ease 0.3s;
        transform-origin: left;
        will-change: transform;

        &:before,
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            content: '';
            background: currentColor;
            height: 1px;

            transition: transform ease 0.3s;
            transform-origin: left;
            will-change: transform;
        }

        &:before {
            top: -.5rem;
            transform: scaleX(1.65);
        }
        &:after {
            top: .5rem;
            transform: scaleX(1.3);
        }

    }

    &:hover {

        .o-menu-trigger__icon {

            transform: scaleX(1);

            &:before,
            &:after {
                transform: scaleX(1);
            }
        }
    }

    [open] & {

        .o-menu-trigger__icon {
            transform: scaleX(1);

            &:before,
            &:after {
                transform: scaleX(0);
            }
        }
    }
}
