.s-intro {

    &__images {
        display: grid;
        grid-auto-flow: column;
    }

    &__content {
        display: flex;
        align-items: center;
        padding: var(--grid-gutter-lg) 0;

        .t-content {
            --content-gap: 3rem;

            @include screen('md') {
                --content-gap: 4rem;
                padding-right: 2ch;
            }

            .-actions {
                display: flex;
                gap: 1rem;
                flex-wrap: wrap;
            }
        }
    }
}
