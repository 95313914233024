.c-btn {
    --btn-text-color: var(--color-snow);
    --btn-bg-color: var(--color-forest);
    --btn-border-color: var(--btn-text-color);
    --btn-hover-text-color: var(--color-snow);
    --btn-hover-bg-color: var(--color-herbs);
    --btn-radius: #{$radius-sm};

	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	padding: 1.3rem;
	border-radius: var(--btn-radius);

	font-size: $fs-sm;
    line-height: 1;
	color: var(--btn-text-color);
	background: var(--btn-bg-color);


    overflow: hidden;
	cursor: pointer;

	text-decoration: none;
	transition: color .15s ease-out;

	&:before,
	&:after {
		content: '';
        @include fit-to-parent();
    }

	&:before {
        border-radius: var(--btn-radius);
    }

	&:after {
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
		transform: scaleY(0);
		transform-origin: top;
		transition: transform .2s ease-in-out;
		background: var(--btn-hover-bg-color);
	}

	&:hover,
    &:active,
    &:focus {
        color: var(--btn-hover-text-color);
		transition: color 0.3s ease-out;

		&:after {
			transform: scaleY(1);
			transform-origin: bottom;
			transition: transform .2s ease-in-out;
		}
	}

	span {
		z-index: 1;
	}

	&.-light {
		--btn-text-color: var(--color-forest);
		--btn-bg-color: var(--color-snow);
	    --btn-hover-text-color: var(--color-snow);
		--btn-hover-bg-color: var(--color-forest);

	}

	&.-transparent {
		--btn-text-color: var(--color-forest);
		--btn-bg-color: transparent;
		--btn-hover-text-color: var(--color-snow);
		--btn-hover-bg-color: var(--color-herbs);
	}

	&.-outline {
		--btn-text-color: var(--color-forest);
		--btn-bg-color: transparent;

        &:before {
            border: 1px solid var(--btn-border-color);
        }
	}

    &-group {
        display: flex;

        > *:first-child {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        > *:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 1px;

            &:is(menu-opener) {
                > * {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }
        }
    }

	.o-icon {
        --svg-width: 0.8em;
		margin-left: 1rem;

		@include screen('sm') {
			margin-left: 3rem;
		}
	}
}
