.o-icon {
    --svg-width: 1em;
    --svg-ratio: 1/1;

    display: inline-block;
    width: var(--svg-width,1em);
    height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
    fill: currentColor;

    svg {
        display: block;
        width: var(--svg-width,1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
        fill: currentColor;
    }

    &-logo  {
        --svg-ratio: 85/299;
    }
    &-symbol  {
        --svg-ratio: 510/597;
    }
}
