%cover {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.o-asset {
    display: block;
    width: 100%;
    background-color: rgba(#000, 0.1);

    overflow: hidden;

    user-select: none;
    pointer-events: none;

    img,
    source,
    .o-asset__img {
        display: block;
        width: 100%;
        height: auto;
    }

    &.-cover {

        .o-asset__img {
            @extend %cover;
        }
    }

    &.-transparent {
        background-color: transparent;
    }

    &.-rounded {
        overflow: hidden;
        border-radius: 50%;
    }

    &.-ratio-auto {
        height: 100%;
    }
    &.-ratio-portrait {
        padding-top: calc( 4 / 3 * 100%);
    }
    &.-ratio-landscape {
        padding-top: calc( 9 / 16 * 100%);
    }
    &.-ratio-square {
        padding-top: 100%;
    }

    &[class*="-ratio-"] {
        height: 0;

        img,
        .o-asset__img {
            @extend %cover;
        }
    }
}
