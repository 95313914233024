.l-loader {

	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;

	display: flex;
	justify-content: center;
	align-items: center;

	width: 100%;
	height: 100%;
	background: $color-forest;
	pointer-events: none;

	&__logo {
        display: block;
		color: $color-snow;
		opacity: 0;
		transition: opacity .4s ease .75s;

		.o-icon {
            --svg-width: 14rem;

			@include screen('sm') {
                --svg-width: 18rem;
			}
			@include screen('lg') {
                --svg-width: 20rem;
			}
		}
	}

	opacity: 0;
	transition: opacity .8s ease 1.25s;
}

.-preload {

	.l-loader {
		opacity: 1;

		&__logo {
			opacity: 1;
		}
	}
}
