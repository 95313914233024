/*
|--------------------------------------------------------------------------
| Typography
|--------------------------------------------------------------------------
|
| Everything related to text styling, from titles to paragraphes while using
| clever shortcut such as `.t-content` and `.t-copy` to format inner elements.
|
*/


/*
|--------------------------------------------------------------------------
| Titles
|--------------------------------------------------------------------------
*/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 1em;
    font-weight: inherit;
}

.t-t1 {
    @include responsive-type($min-font: 29, $max-font: 56);
	font-family: $ff-maison;
	line-height: 1em;
	font-weight: 300;
    letter-spacing: -.025em;

	@include screen('xl') {
        font-size: $fs-xxl;
	}

    &.-alt {
        @include responsive-type($min-font: 24, $max-font: 56);
    }
}

.t-t2,
.t-t3,
.t-t4 {
    font-family: $ff-suisse;
    font-weight: 400;
    letter-spacing: -.025em;
}

.t-t2 {
    @include responsive-type($min-font: 26, $max-font: 44);
    line-height: 1.1em;

    @include screen('xl') {
        font-size: $fs-xl;
    }
}

.t-t3 {
    @include responsive-type($min-font: 22, $max-font: 30);
    line-height: 1.1em;

    @include screen('xl') {
        font-size: $fs-lg;
    }
}

.t-t4 {
    @include responsive-type($min-font: 18, $max-font: 24);
    line-height: 1.2em;

    @include screen('xl') {
        font-size: $fs-md;
    }
}


/*
|--------------------------------------------------------------------------
| Content
|--------------------------------------------------------------------------
*/

.t-content {
    display: grid;
    grid-auto-rows: minmax(min-content, max-content);
    grid-gap: var(--content-gap, 1rem);

    .-actions {
        padding-top: var(--content-actions-extra-gap, 0);
    }

    &.-center {
        justify-content: center;
        text-align: center ;
        align-items: center;
    }
}


/*
|--------------------------------------------------------------------------
| Copy
|--------------------------------------------------------------------------
*/

.t-copy {

    @include responsive-type($min-font: 16, $max-font: 18);
    font-weight: 300;
    line-height: 1.25em;

    @include screen('xl') {
        font-size: $fs-base;
    }

    > * + * {
        margin-top: 1em;
    }

    a {

        &:hover {
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

/*
|--------------------------------------------------------------------------
| Basic
|--------------------------------------------------------------------------
*/
p, address {

    font-style: inherit;

	a {
		text-decoration: underline;
	}
}

a {
	color: currentColor;
	text-decoration: none;
    word-break: break-all;
}
