.l-form {
    display: grid;
    grid-gap: var(--grid-gutter);
    gap: var(--grid-gutter);

    &__combo {
        display: flex;
        width: 100%;

        > * {
            min-width: min-content;

            &:is(.l-form__input) {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                input {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            &:is([type="submit"]) {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    &__input {
        width: 100%;

        label, input {
            font-size: $fs-sm;
            color: $color-forest;
            padding: 1rem;
        }

        label {
            position: absolute;
            top:0;
            left: 0;
            z-index: 1;

            will-change: font-size;
            transform-origin: left top;
            transition: font-size 0.2s ease, transform 0.2s ease;
        }

        input {
            display: block;
            width: 100%;

            background: #FFF;
            border-radius: $radius-sm;

            //transition: box-shadow .1s ease-out;

            &:focus, &:active {
                outline: none;
            }

            &:hover {
                //transition: box-shadow .3s ease-out;
                //box-shadow: 0 0 0.5em rgba($color-forest, 0.25);
            }

            &:focus, &:active {
                //transition: box-shadow .3s ease-out;
                //box-shadow: 0 0 1em rgba($color-forest, 0.4);
            }
        }

        &.-not-empty {
            label {
                transform: translateY(-.8rem);
                font-size: 45%;
            }
        }
    }

    &__notices {

        p {
            font-size: $fs-sm;
            border-left: 1px solid currentColor;
            padding-left: 1em;

            &.-success {
                color: $color-forest;
            }
            &.-error {
                color: $color-rust;
            }
        }
    }
}
