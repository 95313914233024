@font-face {
	font-family: 'Suisse Neue';
	src: url('/public/fonts/SuisseNeue-Regular.woff2') format('woff2'),
		url('/public/fonts/SuisseNeue-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('/public/fonts/MaisonNeue-Book.woff2') format('woff2'),
		url('/public/fonts/MaisonNeue-Book.woff') format('woff');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Maison Neue';
	src: url('/public/fonts/MaisonNeue-Light.woff2') format('woff2'),
		url('/public/fonts/MaisonNeue-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}


