.l-header {

    &__top {
        position: fixed;
        top: 0;
        left:0;
        right: 0;
        z-index: 2;

        display: flex;
        justify-content: space-between;

        color: $color-snow;
    }

    &__logo {

        display: block;
        opacity: 1;
        transition: transform 1.5s $out-quad, opacity 1.2s $in-out-quad;
        transition-delay: 1.3s;

        transition: color .3s ease;

        svg {
            --svg-width: 4rem;

            @include screen('sm') {
                --svg-width: 5.4rem;
            }
            @include screen('md') {
                --svg-width: 7rem;
            }
        }
    }

    &__nav {
        opacity: 1;
        transition: transform 1.5s $out-quad, opacity 1.2s $in-out-quad;
        transition-delay: 1.5s;

        @include screen('lg') {
            transition-delay: 1.6s;
        }

        &--buttons {
            z-index: 10;
        }
        &--menu {
            z-index: 9;
        }
    }


    &__bg {
        position: absolute;
        top: -2rem;
        left: -2rem;
        right: -2rem;
        bottom: 0;

        z-index: -1;

        backdrop-filter: blur(.3rem);
        -webkit-mask: -webkit-gradient(
            linear,
            left 0%,
            left 80%,
            from(rgba(255, 255, 255, 1)),
            to(rgba(255, 255, 255, 0))
        );
    }

    &__hero {
        @include fit-to-parent(relative);
        display: flex;
        align-items: flex-end;
        height: 100vh;
        min-height: 60rem;
        max-height: 120rem;

        /* iOS fix */
        .is-ios & {
            height: -webkit-fill-available;
        }
    }

    &__content {
        color: var(--color-snow);


        hgroup {
            max-width: 20ch;
            padding-right: 2rem;

            // Animations
            transform: translateY(0) skewY(0deg);
            transform-origin: top left;
            opacity: 1;
            transition: transform 1.5s $out-quad, opacity 1.2s $in-out-quad;
            transition-delay: 1.8s;

            > * {
                display: inline;
            }
        }

        @include screen('lg') {
            padding-right: 0;
        }
    }

    &__video {
        @include fit-to-parent();
    }


    body.--hero-hidden & {

        .l-header__logo {
            color: var(--color-forest);
        }
    }
}

.-preload {

    .l-header__logo,
    .l-header__nav {
        transform: translateY(1.25rem);
        opacity: 0;

        @include screen('lg') {
            transform: translateY(2rem);
        }
    }

    .l-header__content {

        hgroup {

            transform: translateY(1.25rem) skewY(3deg);
            opacity: 0;

            @include screen('lg') {
                transform: translateY(2rem) skewY(3deg);
            }
        }
    }
}
