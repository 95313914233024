.o-inline-list {
	list-style: none;

	li {
		display: inline-block;
		padding: 0 0.5em;

		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
	}
}

.o-link {
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: currentColor;

		transform: scaleX(0);
		transform-origin: right;
		transition: transform .2s ease;
	}

	&:hover {
		&:after {
			transform: scaleX(1);
			transform-origin: left;
		}
	}
}
