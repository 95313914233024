// Visibility
.hidden {
	display: none !important;
}

.visually-hidden {
	position: absolute !important;
	overflow: hidden;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(0 0 0 0);
	word-wrap: normal !important;

	&--inline {
		margin: 0;
		height: 1em;
	}

}

.accessible-item {
    position: absolute;
    width: 1px;
    height: 1px;
    white-space: nowrap;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
}

a:empty,
ul:empty,
dl:empty,
div:empty,
section:empty,
article:empty,
p:empty,
h1:empty,
h2:empty,
h3:empty,
h4:empty,
h5:empty,
h6:empty {
	display: none;
}

// Motion
@media (prefers-reduced-motion) {
	.motion-reduce {
		transition: none !important;
	}
}

/*
  Focus ring - none
*/

/* Dangerous for a11y - Use with care */
.focus-none {
  box-shadow: none !important;
  outline: 0 !important;
}


