$color-forest: #303C34;
$color-rust: #AD5A2F;
$color-herbs: #80887D;
$color-snow: #E3E1DE;
$color-white: #FFF;

$ff-suisse: 'Suisse Neue';
$ff-maison: 'Maison Neue';

$fs-sm: 1.6rem;
$fs-base: 1.8rem;
$fs-md: 2.4rem;
$fs-lg: 3.0rem;
$fs-xl: 4.4rem;
$fs-xxl: 5.6rem;

$margin: 2rem;
$gutter: 1rem;
$radius-sm: .6rem;
$radius-lg: 1rem;

$breakpoints: (
    'sm':  ( min-width:  640px ),
    'md':  ( min-width:  768px ),
    'lg':  ( min-width: 1024px ),
    'xl':  ( min-width: 1281px ),
    '2xl': ( min-width: 1536px ),
    '3xl': ( min-width: 1920px )
) !default;


$grid-width: 100%;
$grid-max-width: 220rem;
$grid-columns: 12;
$grid-gutters: (
    'sm':    2rem,
    'md':    2rem,
    'lg':    2rem,
    'xl':    2rem,
    '2xl':   2rem,
    '3xl':   2rem
);

%absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


/*==============================
=            Easing            =
==============================*/


$in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

$in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

$in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);

$in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);

$in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);

$in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);

$in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);

$in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.320, 1.275);
$in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

$out-back-t: cubic-bezier(0.535, 1.650, 0.635, 1.005);
